import React, { useState } from 'react';
import { Card, CardHeader, CardTitle, CardContent } from '@/components/ui/card';
import { Button } from '@/components/ui/button';
import { Badge } from '@/components/ui/badge';
import { cn } from '@/lib/utils';
import { useUser } from "@clerk/clerk-react";
import {
  CalendarDays, Users, MapPin, Clock, Star,
  Zap, Target, TrendingUp, Code, CheckCircle2,
  BookOpen, Users2, Rocket, Brain
} from 'lucide-react';
import { useNavigate } from 'react-router-dom';

export default function AmsterdamWorkshop() {
  const { user } = useUser();
  const [topLogoAnimating, setTopLogoAnimating] = useState(false);
  const [bottomLogoAnimating, setBottomLogoAnimating] = useState(false);
  const [topLogoVisible, setTopLogoVisible] = useState(true);
  const [bottomLogoVisible, setBottomLogoVisible] = useState(true);
  const [logoClickCount, setLogoClickCount] = useState(0);
  const navigate = useNavigate();

  const handleLogoClick = (position: 'top' | 'bottom') => {
    if (position === 'top') {
      setTopLogoAnimating(true);
      setTimeout(() => setTopLogoVisible(false), 1500);
    } else {
      setBottomLogoAnimating(true);
      setTimeout(() => setBottomLogoVisible(false), 1500);
    }

    setLogoClickCount(prev => {
      const newCount = prev + 1;
      if (newCount === 2) {
        // Both logos clicked, redirect to Asteroids after animations
        setTimeout(() => navigate('/asteroids'), 1600);
      }
      return newCount;
    });
  };

  return (
    <>
      <style>
        {`
          @keyframes flyOffTopRight {
            0% { 
              transform: translate(0, 0) rotate(0);
              opacity: 0.2;
            }
            30% { 
              transform: translate(100px, -100px) rotate(0);
              opacity: 0.2;
            }
            100% { 
              transform: translate(200vw, -200vw) rotate(0);
              opacity: 0;
            }
          }
          @keyframes flyOffBottomLeft {
            0% { 
              transform: translate(0, 0) rotate(180deg);
              opacity: 0.2;
            }
            30% { 
              transform: translate(-100px, 100px) rotate(180deg);
              opacity: 0.2;
            }
            100% { 
              transform: translate(-200vw, 200vw) rotate(180deg);
              opacity: 0;
            }
          }
          .fly-off-top {
            animation: flyOffTopRight 2s cubic-bezier(0.1, 0, 0.9, 1) forwards;
          }
          .fly-off-bottom {
            animation: flyOffBottomLeft 2s cubic-bezier(0.1, 0, 0.9, 1) forwards;
          }

          /* Enhanced hover effects for learning tiles */
          .hover-lift {
            transition: all 0.3s ease;
          }
          .hover-lift:hover {
            transform: translateY(-8px);
            box-shadow: 0 12px 24px -10px rgba(0, 0, 0, 0.1);
          }
          .hover-glow:hover {
            box-shadow: 0 0 30px rgba(124, 58, 237, 0.15);
          }
        `}
      </style>
      <div className="min-h-screen relative">
        {/* PPC Hub logo background */}
        <div className="fixed inset-0 pointer-events-none overflow-hidden">
          {topLogoVisible && (
            <img
              src="/images/ppchub-logo.jpeg"
              alt="PPC Hub"
              className={cn(
                "absolute top-20 left-20 w-64 h-64 opacity-20 dark:opacity-10 cursor-pointer pointer-events-auto transition-opacity hover:opacity-30 dark:hover:opacity-20",
                topLogoAnimating && "fly-off-top"
              )}
              onClick={() => handleLogoClick('top')}
            />
          )}
          {bottomLogoVisible && (
            <img
              src="/images/ppchub-logo.jpeg"
              alt="PPC Hub"
              className={cn(
                "absolute bottom-20 right-20 w-64 h-64 opacity-20 dark:opacity-10 rotate-180 cursor-pointer pointer-events-auto transition-opacity hover:opacity-30 dark:hover:opacity-20",
                bottomLogoAnimating && "fly-off-bottom"
              )}
              onClick={() => handleLogoClick('bottom')}
            />
          )}
        </div>

        {/* Global background pattern */}
        <div className="fixed inset-0 pointer-events-none opacity-[0.015] dark:opacity-[0.02]" style={{
          backgroundImage: `url("data:image/svg+xml,%3Csvg width='60' height='60' viewBox='0 0 60 60' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%23000000' fill-opacity='1'%3E%3Cpath d='M36 34v-4h-2v4h-4v2h4v4h2v-4h4v-2h-4zm0-30V0h-2v4h-4v2h4v4h2V6h4V4h-4zM6 34v-4H4v4H0v2h4v4h2v-4h4v-2H6zM6 4V0H4v4H0v2h4v4h2V6h4V4H6z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E")`,
          backgroundSize: '60px 60px'
        }}></div>

        {/* Hero Section with Background */}
        <div className="relative bg-gradient-to-r from-orange-600 to-purple-700 text-white py-8 overflow-hidden">
          {/* Animated background elements */}
          <div className="absolute inset-0 overflow-hidden">
            <div className="absolute w-[500px] h-[500px] -top-48 -left-48 bg-orange-500/30 rounded-full blur-3xl animate-blob"></div>
            <div className="absolute w-[500px] h-[500px] -bottom-48 -right-48 bg-purple-500/30 rounded-full blur-3xl animate-blob animation-delay-2000"></div>
            <div className="absolute w-[500px] h-[500px] top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 bg-pink-500/20 rounded-full blur-3xl animate-blob animation-delay-4000"></div>
          </div>

          {/* Dot pattern overlay */}
          <div className="absolute inset-0" style={{
            backgroundImage: 'radial-gradient(circle at 1px 1px, rgba(255,255,255,0.15) 1px, transparent 0)',
            backgroundSize: '40px 40px'
          }}></div>

          {/* Content */}
          <div className="container mx-auto px-4 max-w-6xl relative z-10">
            <div className="text-center mb-8">
              <Badge variant="secondary" className="mb-4 bg-white/10 backdrop-blur-sm text-lg">March 17th, 2025 • Amsterdam</Badge>
              <h1 className="text-5xl font-bold mb-6">Unlock the Power of Google Ads Scripts</h1>
              <p className="text-2xl mb-6 text-white/90">Get the skills you need for 2025 in one (big) day!</p>

              <div className="flex justify-center gap-6 flex-wrap mb-6">
                <div className="flex items-center gap-2 bg-white/10 backdrop-blur-sm px-4 py-2 rounded-full">
                  <Users className="h-5 w-5" />
                  <span className="font-semibold">Only 49 spots available</span>
                </div>
                <div className="flex items-center gap-2 bg-white/10 backdrop-blur-sm px-4 py-2 rounded-full">
                  <Star className="h-5 w-5" />
                  <span className="font-semibold">PPC Hub Members Early Access</span>
                </div>
              </div>

              <div className="flex justify-center gap-4">
                <Button size="lg" className="hover-lift bg-white text-orange-600 hover:bg-white/90">
                  <a href="https://checkout.ppcmastery.com/checkout/workshop-mike-rhodes-regular">
                    Reserve Your Spot Now
                  </a>
                </Button>
                <Button size="lg" className="hover-lift bg-orange-600 text-white hover:bg-orange-700 border-2 border-white">
                  <a href="https://checkout.ppcmastery.com/checkout/workshop-mike-rhodes-vip">
                    Only 9 VIP Tickets
                  </a>
                </Button>
              </div>
            </div>
          </div>
        </div>

        {/* Main Content */}
        <div className="container mx-auto px-4 py-16 max-w-6xl">
          {/* Overview Section */}
          <div className="mb-16 text-center">
            <h2 className="text-3xl font-bold mb-6">Learn to Create & Master Google Ads Scripts with Mike Rhodes</h2>
            <p className="text-xl text-muted-foreground mb-12">
              Get the skills to create high-impact scripts, save hours weekly, and unlock campaign insights in one power-packed day.
            </p>

            <div className="grid md:grid-cols-2 gap-8 text-center mb-12">
              <Card className="hover-lift hover-glow bg-gradient-to-br from-orange-50 to-purple-50 dark:from-orange-950/20 dark:to-purple-950/20">
                <CardContent className="pt-6">
                  <h3 className="text-xl font-semibold mb-4 flex items-center gap-2 justify-center">
                    <Code className="h-6 w-6 text-orange-600" />
                    Script Creation Fundamentals
                  </h3>
                  <p className="text-muted-foreground">
                    Understand and practice the core concepts,
                    <br />
                    so you can write your own custom scripts from scratch.
                  </p>
                </CardContent>
              </Card>

              <Card className="hover-lift hover-glow bg-gradient-to-br from-purple-50 to-blue-50 dark:from-purple-950/20 dark:to-blue-950/20">
                <CardContent className="pt-6">
                  <h3 className="text-xl font-semibold mb-4 flex items-center gap-2 justify-center">
                    <Zap className="h-6 w-6 text-purple-600" />
                    Script Customization
                  </h3>
                  <p className="text-muted-foreground">
                    Modify existing scripts to match your specific account needs.
                    <br />
                    Once you know how, you'll see opportunities everywhere!
                  </p>
                </CardContent>
              </Card>

              <Card className="hover-lift hover-glow bg-gradient-to-br from-blue-50 to-orange-50 dark:from-blue-950/20 dark:to-orange-950/20">
                <CardContent className="pt-6">
                  <h3 className="text-xl font-semibold mb-4 flex items-center gap-2 justify-center">
                    <Target className="h-6 w-6 text-blue-600" />
                    Performance Optimization
                  </h3>
                  <p className="text-muted-foreground">
                    Deploy proven scripts for anomaly detection, bidding,
                    <br />
                    reporting & more. Whatever you need (within reason)
                  </p>
                </CardContent>
              </Card>

              <Card className="hover-lift hover-glow bg-gradient-to-br from-orange-50 to-purple-50 dark:from-orange-950/20 dark:to-purple-950/20">
                <CardContent className="pt-6">
                  <h3 className="text-xl font-semibold mb-4 flex items-center gap-2 justify-center">
                    <Brain className="h-6 w-6 text-orange-600" />
                    Advanced Troubleshooting
                  </h3>
                  <p className="text-muted-foreground">
                    Master debugging techniques to fix (almost) all
                    <br />
                    script issues with & without AI help
                  </p>
                </CardContent>
              </Card>
            </div>

            <Card className="hover-lift hover-glow bg-gradient-to-r from-purple-50 to-orange-50 dark:from-purple-950/20 dark:to-orange-950/20">
              <CardContent className="pt-6">
                <h3 className="text-xl font-semibold mb-4 text-left">Real-World Applications</h3>
                <div className="grid md:grid-cols-3 gap-6 text-left">
                  <div className="flex items-start gap-2">
                    <CheckCircle2 className="h-5 w-5 text-purple-600 mt-1 flex-shrink-0" />
                    <span>Learn how to integrate AI into your scripts with the AI Whisperer methodology</span>
                  </div>
                  <div className="flex items-start gap-2">
                    <CheckCircle2 className="h-5 w-5 text-purple-600 mt-1 flex-shrink-0" />
                    <span>Transform complex performance data into natural language insights using AI</span>
                  </div>
                  <div className="flex items-start gap-2">
                    <CheckCircle2 className="h-5 w-5 text-purple-600 mt-1 flex-shrink-0" />
                    <span>Build custom alerts to catch performance anomalies and opportunities</span>
                  </div>
                  <div className="flex items-start gap-2">
                    <CheckCircle2 className="h-5 w-5 text-purple-600 mt-1 flex-shrink-0" />
                    <span>Learn advanced spreadsheet integration techniques (QUERY, pivot tables, & more)</span>
                  </div>
                  <div className="flex items-start gap-2">
                    <CheckCircle2 className="h-5 w-5 text-purple-600 mt-1 flex-shrink-0" />
                    <span>Master Google's Query Builder to save time & pain!</span>
                  </div>
                  <div className="flex items-start gap-2">
                    <CheckCircle2 className="h-5 w-5 text-purple-600 mt-1 flex-shrink-0" />
                    <span>Understand how to develop automated client-ready presentations</span>
                  </div>
                </div>
              </CardContent>
            </Card>
          </div>

          {/* Main Content Grid */}
          <div className="grid md:grid-cols-3 gap-8">
            {/* Left Column - Main Content */}
            <div className="md:col-span-2 space-y-8">
              {/* Why Attend Section */}
              <Card>
                <CardHeader>
                  <CardTitle className="flex items-center gap-2">
                    <Brain className="h-6 w-6 text-orange-600" />
                    Why Attend?
                  </CardTitle>
                </CardHeader>
                <CardContent className="space-y-6">
                  <div className="space-y-4">
                    <div className="flex gap-4">
                      <div className="flex-shrink-0">
                        <Code className="h-6 w-6 text-purple-600" />
                      </div>
                      <div>
                        <h4 className="font-semibold">Learn by Doing</h4>
                        <p className="text-muted-foreground">Hands-on exercises designed to give you practical skills</p>
                      </div>
                    </div>
                    <div className="flex gap-4">
                      <div className="flex-shrink-0">
                        <CheckCircle2 className="h-6 w-6 text-purple-600" />
                      </div>
                      <div>
                        <h4 className="font-semibold">Troubleshoot Like a Pro</h4>
                        <p className="text-muted-foreground">Debug deliberate errors and master real-world problem-solving</p>
                      </div>
                    </div>
                    <div className="flex gap-4">
                      <div className="flex-shrink-0">
                        <Rocket className="h-6 w-6 text-purple-600" />
                      </div>
                      <div>
                        <h4 className="font-semibold">Leave Empowered</h4>
                        <p className="text-muted-foreground">Walk away with scripts you can use immediately</p>
                      </div>
                    </div>
                    <div className="flex gap-4">
                      <div className="flex-shrink-0">
                        <Target className="h-6 w-6 text-purple-600" />
                      </div>
                      <div>
                        <h4 className="font-semibold">Build In-Demand Skills (maybe get a payrise)</h4>
                        <p className="text-muted-foreground">Master the most sought-after automation skills in PPC</p>
                      </div>
                    </div>
                  </div>
                </CardContent>
              </Card>

              {/* What You'll Get Section */}
              <Card>
                <CardHeader>
                  <CardTitle className="flex items-center gap-2">
                    <BookOpen className="h-6 w-6 text-orange-600" />
                    What You'll Get (€1,500+ Value)
                  </CardTitle>
                </CardHeader>
                <CardContent>
                  <div className="grid md:grid-cols-2 gap-8">
                    {/* Left Column */}
                    <div>
                      <div className="min-h-[300px]">
                        <h3 className="font-semibold text-lg mb-4">Pre-Workshop Resources</h3>
                        <ul className="space-y-3">
                          <li className="flex items-start gap-2">
                            <span className="text-green-500 mt-1">✓</span>
                            <div>
                              <strong>3 Premium Scripts (€400+ value)</strong>
                              <p className="text-muted-foreground">Mike's PMax, Negative Keywords, and Asset Chart scripts</p>
                            </div>
                          </li>
                          <li className="flex items-start gap-2">
                            <span className="text-green-500 mt-1">✓</span>
                            <div>
                              <strong>5+ Additional Scripts</strong>
                              <p className="text-muted-foreground">Collection of proven automation scripts</p>
                            </div>
                          </li>
                          <li className="flex items-start gap-2">
                            <span className="text-green-500 mt-1">✓</span>
                            <div>
                              <strong>10+ Script Templates & Snippets</strong>
                              <p className="text-muted-foreground">Ready-to-use code for common tasks</p>
                            </div>
                          </li>
                        </ul>
                      </div>

                      <div>
                        <h3 className="font-semibold text-lg mb-4">Workshop Day</h3>
                        <ul className="space-y-3">
                          <li className="flex items-start gap-2">
                            <span className="text-green-500 mt-1">✓</span>
                            <div>
                              <strong>Full-Day Intensive Training</strong>
                              <p className="text-muted-foreground">Hands-on exercises and real-world examples</p>
                            </div>
                          </li>
                          <li className="flex items-start gap-2">
                            <span className="text-green-500 mt-1">✓</span>
                            <div>
                              <strong>Something for everyone</strong>
                              <p className="text-muted-foreground">Go from zero to hero with Mike's help</p>
                            </div>
                          </li>
                          <li className="flex items-start gap-2">
                            <span className="text-green-500 mt-1">✓</span>
                            <div>
                              <strong>Lunch & Refreshments</strong>
                              <p className="text-muted-foreground">Network with peers during breaks</p>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>

                    {/* Right Column */}
                    <div>
                      <div className="min-h-[300px]">
                        <h3 className="font-semibold text-lg mb-4">Post-Workshop Support</h3>
                        <ul className="space-y-3">
                          <li className="flex items-start gap-2">
                            <span className="text-green-500 mt-1">✓</span>
                            <div>
                              <strong>Workshop GPT Access</strong>
                              <p className="text-muted-foreground">AI assistant for ongoing script help</p>
                            </div>
                          </li>
                          <li className="flex items-start gap-2">
                            <span className="text-green-500 mt-1">✓</span>
                            <div>
                              <strong>Follow-up Q&A Session</strong>
                              <p className="text-muted-foreground">90-minute group call 3 weeks after the workshop</p>
                            </div>
                          </li>
                          <li className="flex items-start gap-2">
                            <span className="text-green-500 mt-1">✓</span>
                            <div>
                              <strong>Private Community Access</strong>
                              <p className="text-muted-foreground">Connect with fellow attendees</p>
                            </div>
                          </li>
                        </ul>
                      </div>

                      <div>
                        <h3 className="font-semibold text-lg mb-4">Exclusive Bonuses</h3>
                        <ul className="space-y-3">
                          <li className="flex items-start gap-2">
                            <span className="text-green-500 mt-1">✓</span>
                            <div>
                              <strong>AI Prompt Collection</strong>
                              <p className="text-muted-foreground">My proven prompts for script writing</p>
                            </div>
                          </li>
                          <li className="flex items-start gap-2">
                            <span className="text-green-500 mt-1">✓</span>
                            <div>
                              <strong>Debugging Toolkit</strong>
                              <p className="text-muted-foreground">Troubleshoot scripts like a pro</p>
                            </div>
                          </li>
                          <li className="flex items-start gap-2">
                            <span className="text-green-500 mt-1">✓</span>
                            <div>
                              <strong>Future Updates</strong>
                              <p className="text-muted-foreground">Access to template improvements</p>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </CardContent>
              </Card>
            </div>

            {/* Right Column - Pricing Cards */}
            <div className="space-y-6">
              {/* Standard Ticket */}
              <Card>
                <CardHeader>
                  <CardTitle>Standard Ticket</CardTitle>
                </CardHeader>
                <CardContent className="space-y-4">
                  <div className="text-3xl font-bold">€699</div>
                  <ul className="space-y-2">
                    <li className="flex items-center gap-2">
                      <span className="text-green-500">✓</span>
                      Full workshop access
                    </li>
                    <li className="flex items-center gap-2">
                      <span className="text-green-500">✓</span>
                      Pre-workshop materials
                    </li>
                    <li className="flex items-center gap-2">
                      <span className="text-green-500">✓</span>
                      Workshop GPT access
                    </li>
                    <li className="flex items-center gap-2">
                      <span className="text-green-500">✓</span>
                      Group Q&A session
                    </li>
                  </ul>
                  <Button className="w-full bg-blue-600 hover:bg-blue-700">
                    <a href="https://checkout.ppcmastery.com/checkout/workshop-mike-rhodes-regular">
                      Reserve Your Spot
                    </a>
                  </Button>
                </CardContent>
              </Card>

              {/* VIP Ticket */}
              <Card className="border-2 border-orange-500">
                <CardHeader>
                  <CardTitle className="flex justify-between items-center">
                    <span>VIP Experience</span>
                    <Badge variant="secondary">Limited Availability</Badge>
                  </CardTitle>
                </CardHeader>
                <CardContent className="space-y-4">
                  <div className="text-3xl font-bold text-orange-600">€999</div>
                  <ul className="space-y-2">
                    <li className="flex items-center gap-2">
                      <span className="text-orange-500">✓</span>
                      Everything in Standard
                    </li>
                    <li className="flex items-center gap-2">
                      <span className="text-orange-500">✓</span>
                      Private dinner with Mike & Bob (March 16th)
                    </li>
                    <li className="flex items-center gap-2">
                      <span className="text-orange-500">✓</span>
                      Exclusive VIP Q&A session
                    </li>
                  </ul>
                  <Button className="w-full bg-orange-600 hover:bg-orange-700">
                    <a href="https://checkout.ppcmastery.com/checkout/workshop-mike-rhodes-vip">
                      Upgrade to VIP
                    </a>
                  </Button>
                </CardContent>
              </Card>

              {/* Event Details Card */}
              <Card className="bg-gray-50 dark:bg-gray-900">
                <CardHeader>
                  <CardTitle>Event Details</CardTitle>
                </CardHeader>
                <CardContent className="space-y-4">
                  <div className="space-y-3">
                    <div className="flex items-center gap-2">
                      <CalendarDays className="h-5 w-5 text-muted-foreground" />
                      <span>March 17th, 2025</span>
                    </div>
                    <div className="flex items-center gap-2">
                      <MapPin className="h-5 w-5 text-muted-foreground" />
                      <span>Amsterdam (Location TBC)</span>
                    </div>
                    <div className="flex items-center gap-2">
                      <Clock className="h-5 w-5 text-muted-foreground" />
                      <span>Full-Day Workshop</span>
                    </div>
                    <div className="flex items-center gap-2">
                      <Users2 className="h-5 w-5 text-muted-foreground" />
                      <span>Limited to 49 People</span>
                    </div>
                  </div>
                </CardContent>
              </Card>

              {/* Terms */}
              <div className="text-sm text-muted-foreground">
                <p className="mb-2">All prices in EUR. No refunds available.</p>
                <p>PPC Hub members get priority access to tickets. Public sale starts after member pre-sale period.</p>
              </div>
            </div>
          </div>
        </div>

        {/* Amsterdam Footer Image */}
        <div className="relative w-full h-[300px] mt-16">
          <div className="absolute inset-0 bg-gradient-to-b from-transparent to-black/60 z-10"></div>
          <img
            src="/images/amsterdam.png"
            alt="Amsterdam"
            className="w-full h-[300px] object-cover object-center grayscale opacity-80 hover:grayscale-0 hover:opacity-100 transition-all duration-700"
            style={{
              objectPosition: '50% 75%',
              maxHeight: '300px'
            }}
          />
        </div>
      </div>
    </>
  );
}
