interface Testimonial {
    quote: string;
    author: string;
    role: string;
    image: string;
}

export const testimonials: Testimonial[] = [
    {
        quote: "Mike's Scripts & Sheets Mastery course has helped me **up my Google Ads game**, particularly when it comes to data analysis. The platform can be so clunky, and Mike's course gave me the understanding and tools to **pull my own data** from the platform super easily, and connect what I needed to in a sheet. If you know the what data you need from Google Ads, Mike's course will help you get it out **fast** - and help you turn it into **actionable insights** for your clients!",
        author: "Stacey",
        role: "Freelancer. Google Ads Wizard",
        image: "/images/people/stacey.jpeg"
    },
    {
        quote: "Signing up to the Scripts and Sheets Mastery workshop allowed me to grab **large amounts of specific data** from Google Ads and add it too a Google Sheet to make **better decisions** on client accounts. Before taking the course I was relying on other peoples scripts but now I have the **skillset to manage my own** unique scripts. Mike explains things clearly and thoroughly I really enjoyed this content.",
        author: "Jimmy",
        role: "Agency Owner",
        image: "/images/people/jimmy.jpeg"
    },
    {
        quote: "Using the techniques from the workshop, I've **built a production tool** that records and transcribes data, analyzes it with OpenAI, and presents everything in a dynamic Google Sheet. When I hit Sheet limits, I even managed to **integrate MySQL** using the prompting methods Mike taught. The workshop set me up for success in ways I didn't anticipate - I'm now **building tools I never thought possible**!",
        author: "Cam",
        role: "PPC Consultant",
        image: "/images/people/cam.jpeg"
    },
    {
        quote: "Placeholder testimonial for now",
        author: "Craig",
        role: "Digital Marketing Expert",
        image: "/images/people/craig.jpeg"
    },
    {
        quote: "Placeholder testimonial for now",
        author: "Jyll",
        role: "Marketing Director",
        image: "/images/people/jyll.jpeg"
    },
    {
        quote: "Placeholder testimonial for now",
        author: "Ant",
        role: "PPC Specialist",
        image: "/images/people/ant.jpeg"
    }
]; 