// src/Router.tsx
import React, { useEffect } from "react";
import {
  createBrowserRouter,
  RouterProvider,
  useNavigate,
  useLocation,
  type RouteObject,
  Navigate
} from "react-router-dom";

import RootLayout from "@/components/layouts/root-layout";
import TutorialLayout from "@/components/layouts/tutorial-layout";
import { useUser } from "@clerk/clerk-react";
import { GameStateProvider } from "@/contexts/game-state";
import { db } from '@/services/db'
import { toast } from './components/ui/use-toast'
import ErrorBoundary from '@/components/error-boundary';

// Import pages
import Index from "@/pages/index";
import Analysis from "@/pages/analysis";
import Trends from "@/pages/trends";
import Placements from "@/pages/placements";
import Curve from "@/pages/curve";
import Settings from "@/pages/settings";
import RouteError from '@/route-error';
import Hourly from "@/pages/hourly";
import Buy from "@/pages/buy";
import SignInPage from "@/pages/auth/sign-in";
import SignUpPage from "@/pages/auth/sign-up";
import LearnGoogleAdsScripts from "@/pages/scary";
import NotFound from "@/pages/not-found";
import Help from "@/pages/help";
import Setup from '@/pages/setup'
import Story from "@/pages/story";
import Feedback from "@/pages/feedback";
import WhyPage from "@/pages/why";
import SimPage from "@/pages/sim";
import KeywordsAnalysis from "@/pages/keywords";
import AmsterdamWorkshop from "@/pages/amsterdam";
import MasteryWorkshop from "@/pages/mastery";
import AsteroidsGame from "@/pages/asteroids";
import Test from "@/pages/test";
import Privacy from "@/pages/privacy"
import Terms from "@/pages/terms"
import LearnNLEScripts from "@/pages/nle";
import ScriptHelp from "@/pages/script-help";
import Prompt from "./pages/prompt";
import Brief from "@/pages/brief";
import DataPage from "./pages/data";
import MobilePage from "@/pages/mobile";
import BuildThePage from "@/pages/build";

const DEBUG = process.env.NODE_ENV === 'development'

function LoadingState() {
  if (DEBUG) console.log('Router: Showing loading state')
  return (
    <div className="flex items-center justify-center min-h-screen">
      <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900"></div>
    </div>
  );
}

function ProtectedRoute({ children }: { children: React.ReactNode }) {
  const { isSignedIn, isLoaded } = useUser();
  const location = useLocation();

  if (!isLoaded) {
    return <LoadingState />;
  }

  if (!isSignedIn) {
    return <Navigate to="/sign-in" state={{ from: location }} replace />;
  }

  return <>{children}</>;
}

const routes: RouteObject[] = [
  {
    element: <RootLayout />,
    errorElement: <RouteError />,
    children: [
      // Public routes first
      {
        path: "/sign-in",
        element: <SignInPage />,
      },
      {
        path: "/sign-up",
        element: <SignUpPage />,
      },
      {
        path: "/",
        element: <Index />,
      },
      {
        path: "/settings",
        element: <Settings />,
      },
      {
        path: "/analysis",
        element: <Analysis />,
      },
      {
        path: "/trends",
        element: <Trends />,
      },
      {
        path: "/placements",
        element: <Placements />,
      },
      {
        path: "/curve",
        element: <Curve />,
      },
      {
        path: "/hourly",
        element: <Hourly />,
      },
      {
        path: "/buy",
        element: <Buy />,
      },
      {
        path: "/scary",
        element: <LearnGoogleAdsScripts />,
      },
      {
        path: "/help",
        element: <Help />,
      },
      {
        path: "/setup",
        element: <Setup />,
      },
      {
        path: "/story",
        element: <Story />,
      },
      {
        path: "/feedback",
        element: <Feedback />,
      },
      {
        path: "/why",
        element: <WhyPage />,
      },
      {
        path: "/sim",
        element: <SimPage />,
      },
      {
        path: "/keywords",
        element: <KeywordsAnalysis />,
      },
      {
        path: "/amsterdam",
        element: <AmsterdamWorkshop />,
      },
      {
        path: "/mastery",
        element: <MasteryWorkshop />,
      },
      {
        path: "/build",
        element: <BuildThePage />,
      },
      {
        path: "/asteroids",
        element: <AsteroidsGame />,
      },
      {
        path: "/privacy",
        element: <Privacy />
      },
      {
        path: "/terms",
        element: <Terms />
      },
      {
        path: "/test",
        element: <Test />
      },
      {
        path: "/prompt",
        element: <Prompt />
      },
      {
        path: "/brief",
        element: <Brief />
      },
      {
        path: "/data",
        element: <DataPage />
      },
      {
        path: "/mobile",
        element: <MobilePage />,
      },
      {
        path: "*",
        element: <NotFound />
      }
    ]
  },
  {
    element: (
      <GameStateProvider>
        <TutorialLayout />
      </GameStateProvider>
    ),
    children: [
      {
        path: "/nle",
        element: <LearnNLEScripts />,
      },
      {
        path: "/script-help",
        element: <ScriptHelp />,
      }
    ]
  }
];

// Create and export the router instance
const browserRouter = createBrowserRouter(routes);
export { browserRouter as router };

// Export the Router component
export default function Router() {
  const { isLoaded: isAuthLoaded } = useUser();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    // Handle error recovery
    if (location.state?.error) {
      toast({
        variant: "destructive",
        title: "Error Loading Account",
        description: location.state.error
      });

      // Clear error state
      navigate(location.pathname, {
        replace: true,
        state: {}
      });

      // Clear corrupted storage if needed
      if (location.state.clearStorage) {
        db.delete().then(() => {
          console.log('Cleared corrupted storage');
        });
      }
    }
  }, [location.state]);

  if (!isAuthLoaded) {
    return <LoadingState />;
  }

  return <RouterProvider router={browserRouter} />;
}